var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c("masthead", {
        attrs: { title: "Manage Auto Pay" },
        on: { changed: _vm.searchChanged },
      }),
      _c("ajax-table", {
        attrs: { "table-definition": _vm.tableDefinition },
        scopedSlots: _vm._u([
          {
            key: "cell(companyId)",
            fn: function (data) {
              return [_vm._v("\n      " + _vm._s(data.item.name) + "\n    ")]
            },
          },
          {
            key: "cell(view)",
            fn: function (data) {
              return [
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "link",
                      to: {
                        name: "companyAutopayments",
                        params: { companyId: data.item.id },
                      },
                    },
                  },
                  [_vm._v("\n        View\n      ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }