<template>
  <b-container>
    <masthead
      title="Manage Auto Pay"
      @changed="searchChanged"
    />
    <ajax-table :table-definition="tableDefinition">
      <template slot="cell(companyId)" slot-scope="data">
        {{ data.item.name }}
      </template>
      <template slot="cell(view)" slot-scope="data">
        <b-button
          variant="link"
          :to="{ name: 'companyAutopayments', params: { companyId: data.item.id } }"
        >
          View
        </b-button>
      </template>
    </ajax-table>
  </b-container>
</template>

<script>
import Masthead from '@/components/shared/Masthead'
import AjaxTable from '../components/shared/AjaxTable'

export default {
  name: 'Autopayments',
  components: { AjaxTable, Masthead },
  data() {
    return {
      companies: [],
      query: null,
      appliedFilters: [],
    }
  },
  computed: {
    tableDefinition() {
      return {
        columns: [{ key: 'companyId', label: 'Company' }, { key: 'view', label: '' }],
        url: 'client/companies',
        parameters: {
          search_name: 'search_company_details_page',
          query: this.query,
          filter: this.appliedFilters,
        },
        defaultOrderDirection: 'asc',
        defaultOrderBy: 'name',
      }
    },
  },
  methods: {
    searchChanged(searchObject) {
      this.query = searchObject.query
      this.appliedFilters = searchObject.filters
    },
  },
}
</script>
